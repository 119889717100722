import React, { useState, useEffect } from "react";

import teefuryBirdLogo from "../../assets/teefury-bird.jpg";
import { ReactComponent as ViewLargeIcon } from "../../assets/view-large.svg";

import MailOutlineIcon from "@material-ui/icons/MailOutline";

import {
  CardContainer,
  CardWrapper,
  ImgCard,
  CardFooter,
  Figcaption,
  ArtTitle,
  Caption,
  ArtHeaders,
  ImgCardWrapper,
} from "./art-submissions-card.styles";

import { withStyles } from "@material-ui/core/styles";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import { dateToLocaleDateString } from "../../utils";
import { ApprovedFlag, NewArtistFlag } from "../Common";
import { SITE_BACKEND_FULL_URL } from "../../server";
import { ArtLargePrview } from "../Dialog";

const AdminArtCard = (props: any) => {
  const {
    token,
    previewArt,
    id,
    index,
    artistName,
    isApprovedArtist,
    internalTitle,
    emailColor,
    createdAt,
    handleSelectArtCard,
    openAdminArtApproval,
    backgroundColorCode,
    isSelected,
    creationType,
    isNewArtist,
    scheduleDate,
  } = props;
  const [imageSrc, setImageSrc] = useState("");
  const [emailStatusColor, setEmailStatusColor] = useState({
    color: "#6a6a6a",
    opacity: undefined as number | undefined,
  });

  const [isOpenPreviewDialog, setIsOpenPreviewDialog ] = useState(false);

  useEffect(
    () => {
      const controller = new AbortController();
      const signal = controller.signal;
      const fetchImage = () => {
        const thumbImg = `/api/art-submissions-thumb/?src=${previewArt.substring(
          20
        )}`;
        fetch(thumbImg, { signal, headers: { Authorization: `JWT ${token}` } })
          .then((res) => {
            return res.blob();
          })
          .then((blob) => {
            setImageSrc(URL.createObjectURL(blob));
          })
          .catch((error) => {
            // console.error(error);
          });
      };
      
      if (!previewArt) {
        setImageSrc(teefuryBirdLogo);
      } else {
        fetchImage();
      }

      _changeEmailStatusColor();
      return () => {
        controller.abort();
      };
    },
    //eslint-disable-next-line
    [token, previewArt]
  );

  const _changeEmailStatusColor = () => {
    const emailStatusColor = JSON.parse(emailColor);
    if (emailStatusColor.color === "#6a6a6a") {
      setEmailStatusColor({ color: "white", opacity: 0 });
    } else {
      setEmailStatusColor(emailStatusColor);
    }
  };

  const StyledCircleCheckedFilled = withStyles({
    root: {
        width: "25px",
        height: "25px",
        borderRadius: "30px",
        color: "#007FA3",
        position: "absolute",
        right: "10px",
        top: "10px",
    }  
})(CircleCheckedFilled);


  const getImageSrcURL = (imgSrc: string) => {
    if (!imgSrc) {
      return null;
    }
    const serverURL =  SITE_BACKEND_FULL_URL.replace('/backend', '');
    const imgPath = imgSrc.replace('api/', '');
    return serverURL+imgPath;
  }

  const enlargeArt = (e: any) => {
    e.stopPropagation();
    setIsOpenPreviewDialog(true);
  }

  return (
    <CardContainer>
      <CardWrapper
        onClick={handleSelectArtCard}
        id={id}
        style={{ margin: "18px 20px" }}
        data-selected={isSelected}
      >
        <ImgCardWrapper style={{ backgroundColor: `#${backgroundColorCode}`, position: 'relative' }}>
          <ImgCard
            src={imageSrc ? imageSrc : teefuryBirdLogo}
            alt={internalTitle}
            loaded={imageSrc ? "true" : ""}
          />
          <div style={{ position: 'absolute', left: '15px', bottom: '15px' }} onClick={enlargeArt}>
            <ViewLargeIcon />
          </div>
        </ImgCardWrapper>
        <Figcaption>

          {isSelected ? ( <StyledCircleCheckedFilled/> ) : null }

          <ArtTitle style={{ fontSize: "16px", marginTop: "20px" }}>
            {internalTitle.toUpperCase()}
          </ArtTitle>
          <Caption>Artist:</Caption>
          <ArtHeaders>
            {artistName}
            {isApprovedArtist == 'YES' && <ApprovedFlag />}
            {isNewArtist == 'YES' && <NewArtistFlag />}
          </ArtHeaders>

          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
            <div>
              <Caption>Submitted on:</Caption>
              <ArtHeaders style={{ fontSize: "14px" }}>{ dateToLocaleDateString(new Date(createdAt))}</ArtHeaders>
            </div>
            <div>
              <Caption>Creation Type:</Caption>
              <ArtHeaders style={{ fontSize: "14px" }}>{creationType ?? 'None'}</ArtHeaders>
            </div>
          </div>

          <div>
            <Caption>Schedule Date:</Caption>
            <ArtHeaders style={{ fontSize: "14px" }}>{ scheduleDate ? dateToLocaleDateString(new Date(scheduleDate)) : 'None' }</ArtHeaders>
          </div>
          
          <MailOutlineIcon style={emailStatusColor} />
        </Figcaption>
        <CardFooter id={id} data-index={index} onClick={openAdminArtApproval}>
          Review Artwork
        </CardFooter>
      </CardWrapper>

      <ArtLargePrview 
        imageDataUrl={getImageSrcURL(previewArt)} 
        isOpen={isOpenPreviewDialog} 
        handleClose={() => setIsOpenPreviewDialog(false)}
        backgroundColor={`#${backgroundColorCode}`}
      />
    </CardContainer>
  );
};

export default AdminArtCard;
